/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

/** Import helpers section **/
import './usersStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
const UsersView = ({
  loading,
  users,
  onFilterUsers,
  onAddUser,
  onChangePassword,
  onEditUser,
  onDeleteUser,
  onActiveUser
}) => {
  const ActiveCell = ({ row }) => {
    if (row.active)
      return <CheckCircleOutlineIcon className="g-activeicon active users_active" onClick={() => onActiveUser(row)} />;
    return <NotInterestedIcon className="g-activeicon users_active" onClick={() => onActiveUser(row)} />;
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <LockIcon
          className="g-row-action-button"
          onClick={() => onChangePassword(row)}
        />
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditUser(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteUser(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: 'first_name',
        sortable: true,
        center: true
      },
      {
        name: 'Apellido',
        selector: 'last_name',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Rol',
        selector: 'role_title',
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Usuarios" />
      <Paper className="g-page-header users_header">
        <div className="users_header_filterby">
          <FilterBy onChange={onFilterUsers} />
        </div>
        <Button onClick={onAddUser} startIcon={<AddCircleIcon />}>
          Agregar nuevo usuario
        </Button>
      </Paper>

      <DataTable data={users} columns={tableColumns} loading={loading} />
    </>
  );
};

export default UsersView;
